@import "reset.css";

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Regular.ttf') format('truetype');
}
  
body {
    margin: 0;
    font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* **** **** Scrollbar **** **** */
/* https://alligator.io/css/css-scrollbars/ */
/* Firefox (emergine W3C standard) */
* {
    scrollbar-width: 0;
}

/* Chrome/Edge/Safari (depricated standard) */
/* 8px equiv. to "scrollbar-width: thin;" (only tested on desktop) */
*::-webkit-scrollbar {
    width: 0px;
}

*::-webkit-scrollbar-track {
    background: var(--c-background);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--c-primary-dark);
    border-radius: 2px;
    border: 1px solid var(--c-background);
}

@media screen and (min-width: 550px) {
    nav ul {
        width: auto;
        margin-top: 0;
    }

    header #language-selection {
        order: 1;
    }
}

nav .separator {
    padding-left: 1rem;
    padding-right: 1rem;
}

select {
    max-width: 3rem;
}
option {
    overflow: hidden;
    text-overflow: ellipsis;
    border: solid 1px #DDDDDD;
}

/* **** **** Links **** **** */
a {
    color: var(--c-primary);
    text-decoration: none;
}

a:hover {
    color: var(--c-primary-light);
    text-decoration: underline;
}

/* **** **** Visualisation: Loading **** **** */
.visualisation-loading-spinner-container {
    /* H/V centre spinner and text */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* If in flex box (emissions page), grow */
    flex-grow: 1;
}

.visualisation-loading-spinner-container > * {
    padding: 2rem;
}

.visualisation-loading-spinner-container img {
    align-self: center;

    /* Sensible spinner size */
    width: 3rem;
    height: 3rem;
}


/* **** **** Visualisation: Dud **** **** */
.visualisation-dud {
    /* H/V centre spinner and text */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.visualisation-dud h1 {
    font-size: 4rem;
    color: var(--c-secondary);
}

.visualisation-dud > * {
    padding: 1rem;
}

/* **** **** Visualisation: MultiPieChart **** **** */
.multichart-container {
    /* Internal selctor and vis. as full height */
    display: flex;
    flex-direction: column;

    /* Fill parent height */
    flex-grow: 1;
}

/* MultiPieSecletor */
.multichart-selector {
    /* Selector should not grow */
    flex-grow: 0;

    /* Inner horizontal flex */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.multichart-selector-option {
    /* Pad each option */
    padding: 0.5rem 1.5rem;
    margin: 1rem;

    /* Make it a quasi-button */
    font-weight: normal;
    border: 2px solid var(--c-primary);
}

.multichart-selector-option:hover {
    cursor: pointer;
}

.multichart-selector-selected {
    background-color: var(--c-primary);
}


/* MutliPieVisualisation */
.multichart-visualisation-container {
    /* Vis. should grow (to max height) */
    flex-grow: 1;
}


/* **** **** Visualisation: Emissions Page **** **** */
.d3-choropleth {
    /* Make choropleth grow to parent size */
    /* HACK: overflow, fix this later */
    width: 100%;
    height: calc(100% - 3px);
}
.choroParent{
    position: relative;
    width: 100%;
    min-height: 75vh !important;
    height: 75vh !important;
}
.choro{
    position: relative;
    height: 100%;
    z-index: 1;
}
.chorolegend{
    top: 1.7%;
    right: 0.85%;
    padding: 8px;
    z-index: 2;
    width: 280px;
    font:inherit;
    position:absolute;
    background: #FFFFFF;
    border: 2px solid #E6F1C8;
    border-radius: 4px;
}

/* **** **** Visualisation: Global Glance Page **** **** */
.global-glance-page-visualisation-container {
    display: flex;
    flex-direction: row;

    /* Fill parent height */
    flex-grow: 1;
}

.global-glace-page-grid {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-auto-rows: auto;
}


/* **** **** Visualisation: EU Glance Page **** **** */
.eu-glance-page {
    /* Centre child */
    width: 100%;
    display: flex;
    justify-content: center;

    /* Content shadow */
    /*box-shadow: 0px 0px 8px 1px var(--c-text);*/
}

.eu-glance-page-selector {
    /* Display the options in a vertical list. */
    display: flex;
    flex-direction: column;

    /* Separating bar */
    border-right: 2px solid var(--c-primary);
    margin-right: 1rem;
}

a.eu-glance-page-option {
    color: var(--c-text);
    font-weight: normal;

    /* Align option left */
    text-align: left;

    /* Pad */
    padding: 1rem;
    margin: 0.25rem 0rem;
}

.eu-glance-page-option-selected {
    background-color: var(--c-primary);
}

.eu-glance-page-visualisation-container {
    display: flex;
    flex-direction: row;

    /* Fill parent height */
    flex-grow: 1;
}

.eu-glance-page-visualisation-container-normal {
    display: flex;
    flex-direction: column;

    /* Fill parent height */
    flex-grow: 1;
}

.eu-glace-page-grid {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-auto-rows: auto;
}

.eu-glance-page-long {
  grid-column-start : 1;
  grid-column-end: 2;
  grid-row-start : 1;
  grid-row-start : 1;
}


/* **** **** Visualisation: Esp Glance Page **** **** */
.esp-glance-page {
    border: 1px solid red;
    /* Centre child */
    width: 100%;
    display: flex;
    justify-content: center;
}

.esp-glance-page-selector {
    /* Display the options in a vertical list. */
    display: flex;
    flex-direction: column;

    /* Separating bar */
    border-right: 2px solid var(--c-primary);
    margin-right: 1rem;
}

a.esp-glance-page-option {
    color: var(--c-text);
    font-weight: normal;

    /* Align option left */
    text-align: left;

    /* Pad */
    padding: 1rem;
    margin: 0.25rem 0rem;
}

.esp-glance-page-option-selected {
    background-color: var(--c-primary);
}

.esp-glance-page-visualisation-container {
    display: flex;
    flex-direction: column;

    /* Fill parent height */
    flex-grow: 1;
}


/* **** **** Pages **** **** */
.centre-container {
    /* Fill parent */
    flex-grow: 1;

    /* Allow child to fill */
    display: flex;
    flex-direction: column;
}

.centre-container > * {
    /* Children fill centre container */
    flex-grow: 1;
}

.centre-container h1 {
    color: var(--c-text);
    font-size: 5rem;
}

p.no-text {
    color: var(--c-primary);
    font-weight: normal;
    font-size: 5rem;
    padding: 1rem;
}

p.back-text {
    color: var(--c-primary);
    font-weight: normal;
    font-size: 3rem;
    padding: 1rem;
}

.fa-sad-tear {
    color: var(--c-primary);
    font-size: 5rem;
    align-self: center;
    padding: 2rem;
}


/* **** **** Pages: Scrollable inner page **** **** */
.scrollable-inner-page-wrapper {
    /* Centre align conent */
    display: flex;
    justify-content: center;
}

.scrollable-inner-page {
    /* A4 Page on page effect */
    margin: 1rem;
    /*box-shadow: 0px 0px 8px 1px var(--c-text);*/

    /* A4 width */
    padding: 2rem 3rem;
    max-width: calc(210mm - 8rem);  /* A4 - 2 * this pad - 2 * p/h1/h2 pad */

    /* Scrollable */
    max-height: 84vh;
    overflow-y: scroll;
}

/* TODO: Have this automatically adjust, withou media query */
@media only screen and (max-height: 1040px) {
    /* Smaller scrollable */
    .scrollable-inner-page { max-height: 75vh; }
}
@media only screen and (max-height:  830px) {
    /* Smaller scrollable */
    .scrollable-inner-page { max-height: 70vh; }
}
@media only screen and (max-height:  690px) {
    /* Smaller scrollable */
    .scrollable-inner-page { max-height: 55vh; }
}
@media only screen and (max-height:  520px) {
    /* Smaller scrollable */
    .scrollable-inner-page { max-height: 40vh; }
}

/* Page typography */
.scrollable-inner-page h1,
.scrollable-inner-page h2,
.scrollable-inner-page h3,
.scrollable-inner-page p {
    /* Pad all */
    padding: 1rem;
}

.scrollable-inner-page h1,
.scrollable-inner-page h2,
.scrollable-inner-page h3 {
    /* Heading colour */
    color: var(--c-primary);
}

.scrollable-inner-page h2,
.scrollable-inner-page h3 {
    font-style: italic;

    /* Only title (h1) should be centre aligned */
    text-align: left;
}

.scrollable-inner-page h1 { font-size: 4rem; }
.scrollable-inner-page h2 { font-size: 2rem; }
.scrollable-inner-page h3 { font-size: 1rem; }

.scrollable-inner-page p {
    color: var(--c-text);
    font-size: 1rem;
    text-align: left;
}

.scrollable-inner-page hr {
    margin: 1rem;
}


/* **** **** Pages: 404 Page **** **** */
.fa-404-icon {
    color: var(--c-primary);
    font-size: 12em;
}


/* **** **** Demo Stylings **** **** */
.demo-container {
    width: 80%;
    transform: translateX(12.5%);
}

.demo-tabs {
    display: flex;
    background-color: var(--c-primary-dark);
}

.demo-tab {
    flex-grow: 1;

    padding: 0.3em;
    padding-top: 0.5em;
    margin-bottom: 0.5em;

    background-color: var(--c-primary);

    /* typeogtpahy */
    font-size: 1.4rem;
}
.demo-tab:hover { cursor: pointer; }

.active {
    background-color: var(--c-primary-dark);
}

.demo-content {
    min-height: 700px;
}
