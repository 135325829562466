/* SIDENAV BUTTON OVERRRIDES */
.sidenav-dropdown {
    display: flex;
    flex-direction: column;
}

.sidenav-dropdown-item:hover {
    background-color: #1C1B1F1F !important;
}

.sidenav-dropdown-link {
    text-decoration: none !important;
}

.MuiListItemButton-root:hover {
    background-color: #1C1B1F1F !important;
}

.Mui-selected {
    background-color: #D3F6DF !important;
    font-weight: 600 !important;
    color: #000000 !important;
}


/* LOGO */
.logo-container {
    display: flex;
    justify-content: start;
    flex-direction: row;
    width: 100%;
    height: 50px;
    margin-bottom: 7px;
    padding-left: 20px;
}

.logo-container :hover {
    cursor: pointer;
}

.logo-image {
    width: 58px;
}

.logo-image-full {
    width: 148px;
}