.summary-row {
    display: flex;
    flex-direction: row;
    padding: 16px 24px;
    background: #F2F8F4;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    margin-bottom: 16px;
}

.summary-text {
    display: flex;
    flex-direction: column;
    width: 58%;
}

.filters-container {
    width: 32%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;
}

.view-summary-button-container {
    width: 10%;
    margin: auto;
}

.summary-header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}