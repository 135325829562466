/* CONTAINERS */
.top-level-container {
    display: flex;
    min-height: 100vh;
    max-height: max-content;
    background-color: #EEEEEE;
}

.main-container {
    padding: 10px;
    width: 50%;
    max-height: 97%;
    position: fixed;
    overflow-y: scroll;
    /*width defined in sx*/
}

.view-container {
    border-radius: 8px;
    min-height: 80vh;
    background-color: #FFFFFF;
    flex-grow: 1;
    margin-left: 16px;
    margin-right: 16px;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
}
