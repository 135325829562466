.box {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    z-index: 0;
    position: relative;
}

.login-box {
    width: 250px;
}

.MuiPopover-paper {
    border-radius: 24px 0px 24px 24px !important;
}

.register-box {
    width: 250px;
}

.summary-box {
    width: 250px;
}

.change-password-box {
    width: 250px;
}

.forgot-password-box {
    width: 250px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px;
}

.summary-body {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-break {
    height: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px !important;
    font-weight: 250 !important;
    line-height: 16px;
}

.link-container {
    display: flex;
    flex-direction: row;
}

.link {
    text-decoration: none !important;
    cursor: pointer;
}

.ts-and-cs {
    margin-top: 15px;
    margin-bottom: 15px;
}

.button {
    height: min-content;
    font-size: 14px !important;
    border-radius: 100px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.submit-button {
    color: #FFFFFF !important;
}

.forgot-password {
    margin-top: 15px;
    margin-bottom: 15px;
}