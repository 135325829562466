.page-selector-container {
    display: flex;
    flex-direction: row;
}

.page-selector {
    width: 100%;
    margin-bottom: 32px;
}

.MuiTabs-root {
    color: #000000;
    background-color: #F2F8F4 !important;
}

.choropleth-container {
    max-height: 50% !important;
}

.global-glance-page-visualisation-container-normal {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}